import { render, staticRenderFns } from "./MarketAccountDone1.vue?vue&type=template&id=fa0ed3d6&scoped=true&"
import script from "./MarketAccountDone1.vue?vue&type=script&lang=js&"
export * from "./MarketAccountDone1.vue?vue&type=script&lang=js&"
import style0 from "./MarketAccountDone1.vue?vue&type=style&index=0&id=fa0ed3d6&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa0ed3d6",
  null
  
)

export default component.exports